import store from "@/store";
import { router } from "@/Study/main";
import { where } from "firebase/firestore";
import { ChatQuestion } from "@/ts/interfaces/Question";
import { goToQuestionnaire, record, tokenResult } from "@/Study/helpers";
import { keyBy } from "lodash";

export const getQuestionnaireList = () => {
    const { hasCorona } = store.state.study!;
    const used = Boolean(store.state.firebase!.claims.usedAt);

    return !used ? (hasCorona ? ['activePre', 'active'] : ['recovered']) : ['active'];
}

export const getRecordParams = (isCSV: boolean = false) => {
    if(isCSV) return { useCache: false };

    const isActive = router!.currentRoute.value.params.qualifier === 'active';
    return { useCache: !isActive, filters: [where('hasCorona', '==', isActive)] }
}

export const getRouteFromData = (data: tokenResult) => {
    const { hasCorona, filledRecently, filledToday, usedAt } = data;

    if(hasCorona && !filledRecently && !filledToday){
        goToQuestionnaire();
        return { stop: true };
    }

    if(!usedAt)
        return 'agreement';
    if(hasCorona && filledRecently)
        return 'filled-recently';
    if(!hasCorona || filledToday)
        return 'filled';
}

export const allQuestionnaires = ['recovered', 'activePre', 'active'];

export const applyModifications = (questionnaire: string, questions: ChatQuestion[]) => {
    const modifications = allModifications[questionnaire as keyof typeof allModifications];
    
    modifications?.forEach(modification => {
        const index = questions.findIndex(q => q.id === modification.id);
        if(modification.action === 'modify')
            return Object.assign(questions[index], (modification as modAction).updates);
        else if(modification.action === 'remove')
            questions.splice(index, 1);
    });
    
    return questions;
}

type modAction = { action: 'modify', id: number, updates: { [key: string]: any } }
const remove80 = { action: 'remove', id: 80 }
const allModifications = {
    recovered: [
        {
            action: 'modify',
            id: 136,
            updates: {
                text: "האם [g:נעזרת,נעזרת,נעזרתם], בעת ש[g:חלית,חלית,חליתם], באחד מהדברים הבאים כדי להשפיע על מהלך המחלה?"
            }
        },
        remove80
    ],
    activePre: [
        remove80
    ]
}

export const getInviteMessage = (link: string) => {
    return `זה הקישור לשאלון שלך. תודה על השתתפותך 🦋\n\r${link}\n\rנתקלת בבעיות טכניות במהלך מילוי השאלון? ניתן לפנות אלינו לעזרה.\nשימו לב: הקישור הוא אישי לכל נבדק. מכירים אנשים נוספים שמעוניינים למלא את השאלון? הפנו אותם אל coronastudy.co.il/whatsapp`
}

export const additionalHardcodedColumns = [
    ['agreedToShare', 'מוכן לחלוק'],
    ['hasCorona', 'חולה פעיל']
]

export const addColumnsToCSV = (records: record[], questions: ChatQuestion[], rows: any[][], columns: any[]) => {
    const MCASQuestionIds = [7, 8, 168];
    const MCASQuestions = questions.filter(({ id }) => MCASQuestionIds.includes(id));
    if(MCASQuestions.length !== MCASQuestionIds.length)
        return; // We don't have all the questions necessary to calculate the MCAS score

    records.forEach(({ responses }, index) => {
        const MCASResponses = Object.values(responses).filter(({ question_id }) => {
            return MCASQuestionIds.includes(question_id)
        });
        if(MCASResponses.length !== MCASQuestions.length)
            return rows[index].push("", "");

        const responsesById = keyBy(MCASResponses, "question_id");
        const getAnswerCount = (questionId: number) => {
            const { value } = responsesById[questionId];
            if(!Array.isArray(value)) return 0;
            
            return (responsesById[questionId].value as (string | number)[])
                .filter(item => item !== null && item < 99)
                .length
        }

        const MCASValue = getAnswerCount(7) + getAnswerCount(8)
        const hadSymptomsBeforeCorona = responsesById[168].value !== 2

        rows[index].push(...(hadSymptomsBeforeCorona ? [MCASValue, ""] : [0, MCASValue]))
    });

    columns.push("MCAS Score", "Post-Corona MCAS Score")
}

/*
בשאלה הבאה חשוב שתצייני... בהמשך תישאל  גם על מועד התחלת התסמינים שאתה סובל מהם כעת.
בהמשך [g:תישאל,תישאלי,תישאלו]  גם על מועד התחלת התסמינים ש[g:אתה סובל,את סובלת,אתם סובלים] מהם כעת.

במקום "האם התסמינים מהם את סובלת היום קיימים...?", "התסמינים מהם אני סובל נמשכים:" ["כבר שנים רבות", "מאז שחוסנתי", "מאז שחליתי בקורונה"]
אם התשובה היא שנים רבות או מאז שחוסנתי - "האם אחרי שחלית בקורונה חל שינוי בתסמינים האלו?" ["לא", "כן, הם החמירו", "כן, הם השתפרו"]

122----"האם יש תרופה נוספת..." לשנות ל-"האם היו תרופות נוספות, תוספי מזון, צמחי מרפא, או משהו מיוחד באורח החיים שלך לפני שחלית בקורונה שאתה חושב שהשפיע על מהלך המחלה במקרה שלך?"

130----"מה מהדברים הבאים עזר לך להחלים טוב יותר", להוסיף אפשרות "העובדה שחוסנתי" + "אחר"
	"מה מהדברים הבאים השפיע לרעה" צריך להיות checkboxes. להוסיף גם "אחר"

"האם את נעזרת או נעזרת בעבר..." לשנות ל-"האם בעת שחלית נעזרת באחד מהדברים הבאים כדי להשפיע על מהלך המחלה?" בשביל חולים בעבר, חולים בהווה להשאיר כמו שזה
האם בעת ש[g:חלית נעזרת,חלית נעזרת,חליתם נעזרתם] באחד מהדברים הבאים כדי להשפיע על מהלך המחלה?
*/